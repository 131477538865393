angular.module('app').service('dangerThresholds', function($rootScope, $window, $timeout, _view, _chartService, _logicService) {
    var vm = this;

    const BORDER = 60,
          MARGIN = BORDER / 4,
          TEXT_MARGIN = MARGIN * 1.5,
          TRIANGLE_MARGIN = TEXT_MARGIN,
          TITLE_MARGIN = MARGIN * 1.2,
          EDIT_FIELD_MARGIN = MARGIN * 2.4,
          EDIT_FIELD_WIDTH = MARGIN * 3;

    const KEY_CODES = {
        BACKSPACE: 8,
        CMD: {
            RIGHT: 93,
            LEFT: 91
        },
        ALT: 18,
        CTRL: 17,
        ESCAPE: 27,
        ENTER: 13,
        ARROW: {
            LEFT: 37,
            RIGHT: 39
        }
    };

    const EMPTY = 'n/a';

    /**
     * Saves all canvas constants are needed for service.
     * Width, height and bounds will be calculated later.
     * @type {Object}
     */
    var canvas = {
        width: null,
        height: null,
        bounds: null,
        line: {
            stroke: {
                width: 3,
                color: 'rgba(255, 255, 255, .3)'
            },
            text: {
                fill: 'rgba(255, 255, 255, .7)'
            }
        },
        circle: {
            radius: 8,
            fill: '#fff',
            stroke: {
                active: '#e19c25',
                inactive: '#999',
                width: 2
            }
        },
        triangle: {
            fill: '#e19c25'
        },
        strokeLinecap: 'round',
    };

    var elements = {
        aside: null
    };

    /**
     * Saves all stages constants are needed for service
     * Amount, values and width will be calculated later.
     * @type {Object}
     */
    var stages = {
        amount: null,
        values: null,
        width: null,
    }

    var settings = null;

    var is = {
        pointEditing: false,
    };

    function _setPointEdit(pe) {
        is.pointEditing = pe;
    }

    function _getPointEdit() {
        return is.pointEditing;
    }

    function _saveOptions(o) {
        settings = o;
    }

    function configureCanvas() {
        let aside = elements.aside;

        _setCanvasSize();

        function _setCanvasSize() {
            canvas.width = $window.innerWidth - aside.width() - BORDER * 2;
            canvas.height = aside.height() - BORDER * 2;

            return canvas;
        }
    }

    function configureStages() {
        _setStagesAmount() && _setStagesValues() && _setStagesWidth();

        function _setStagesAmount() {
            let dangerThresholds = getDangerThresholds();

            stages.amount = _.size(dangerThresholds);

            return stages;
        }

        function _setStagesValues() {
            stages.values = _.values(settings.crop.stages);
            return stages;
        }

        function _setStagesWidth() {
            stages.width = canvas.width / stages.amount;
            return stages;
        }
    }

    function configureBoundariesForCanvas() {
        canvas.bounds = {
            min: _toPixels({ x: 0, y: 0 }),
            max: _toPixels({ x: (stages.amount - 1), y: settings.maxThreshold }),
            average: function(coord) {
                return Math.abs((this.max[coord] - this.min[coord])) / 2 + BORDER;
            }
        }
    }

    function configureCanvasAndStages() {
        configureCanvas();
        configureStages();
        configureBoundariesForCanvas();
    }

    function clearCanvas() {
        d3.select('#thresholds-graph').select('*').remove();
    }

    function updateCanvasAndStages() {
        clearCanvas();
        configureCanvasAndStages();
    }

    function getCanvasContainer() {
        let wrapper = d3.select('#thresholds-graph');

        return wrapper.append('svg').attr('id', 'canvas')
                         .attr('width', canvas.width)
                         .attr('height', canvas.height);
    }

    function getGraphPoints() {
        let dangerThresholds = getDangerThresholds();
        return _.map(dangerThresholds, (threshold, i) => {
            return {
                center: _toPixels({x: i, y: threshold.value}),
                threshold: threshold
            };
        });
    }

    function getDangerThresholds() {
        return settings.thresholds[settings.thresholdKey].values;
    }

    function getGraphLegend() {
        let top = _configureLinesLevels([canvas.bounds.min.x, canvas.bounds.max.y, canvas.bounds.max.x, canvas.bounds.max.y], 2);
        let middle = _configureLinesLevels([canvas.bounds.min.x, canvas.bounds.average('y'), canvas.bounds.max.x, canvas.bounds.average('y')], 1);
        let bottom = _configureLinesLevels([canvas.bounds.min.x, canvas.bounds.min.y, canvas.bounds.max.x, canvas.bounds.min.y], 0);

        function _configureLinesLevels(coordinates, i) {
            let xMin = coordinates[0],
                yMin = coordinates[1],
                xMax = coordinates[2],
                yMax = coordinates[3];

            let index = i;

            return {
                start: {
                    x: xMin,
                    y: yMin
                },
                end: {
                    x: xMax,
                    y: yMax
                },
                index: i
            };
        }

        return {
            top: top,
            middle: middle,
            bottom: bottom
        };
    }

    function _toPixels(value) {
        let x = BORDER + (canvas.width - 2 * BORDER) * (value.x / (stages.amount - 1)),
            y = value.y !== null ? _chartService.pointInsideMinMax(canvas.height, 0, settings.maxThreshold, value.y, BORDER) : null;

        return { x: x, y: y };
    }

    function drawLegendOnTheGraph(container, points) {

        _.each(points, point => {_drawLegendLine(point) && _drawLegendText(point)});

        function _drawLegendLine(legend) {
            let legendX = [legend.start.x, legend.end.x],
                legendY = [legend.start.y, legend.end.y];

            let stroke = {
                linecap: canvas.strokeLinecap,
                color: canvas.line.stroke.color,
                width: canvas.line.stroke.width
            };

            container.append('line').attr('x1', legendX[0])
                                      .attr('y1', legendY[0])
                                      .attr('x2', legendX[1])
                                      .attr('y2', legendY[1])
                                      .attr('stroke-linecap', stroke.linecap)
                                      .attr('stroke', stroke.color)
                                      .attr('stoke-width', stroke.width);
            return true;
        }

        function _drawLegendText(legend) {
            let xPos = '98%',
                yPos = legend.end.y + canvas.line.stroke.width;

            let text = {
                color: canvas.line.text.fill,
                fontSize: '14px'
            };

            let textLegend = settings.maxThreshold / 2 * legend.index;

            container.append('text').attr('text-anchor', 'middle')
                                      .attr('x', xPos)
                                      .attr('y', yPos)
                                      .attr('transform', 'translate(-5)')
                                      .attr('fill', text.color)
                                      .text(textLegend)
                                      .style('font-size', text.fontSize);
            return true;
        }
    }

    function drawPathLineOnTheGraph(container, points) {
        let dataPoints = _configurePoints();

        _drawPathLine();

        function _configurePoints() {
            let pointX,
                pointY,
                minBound = canvas.bounds.min.y + MARGIN;

            return _.map(points, point => {
                pointX = point.center.x;
                pointY = point.center.y;

                pointY === null ? pointY = minBound: pointY;

                return [pointX, pointY];
            });
        }

        function _drawPathLine() {
            let stroke = {
                linecap: canvas.strokeLinecap,
                color: canvas.circle.stroke.active,
                width: canvas.line.stroke.width,
                fill: 'none'
            };

            let antialiasing = d3.line()
                                 .curve(d3.curveStepAfter);

            container.insert('path', 'g')
                     .datum(dataPoints)
                     .attr('stroke', stroke.color)
                     .attr('stroke-width', stroke.width)
                     .attr('stroke-linecap', stroke.linecap)
                     .attr('fill', stroke.fill)
                     .attr('class', 'thresholds-path')
                     .attr('d', antialiasing);
        }
    }

    function clearPathLineOnTheGraph() {
        d3.select('path.thresholds-path').remove();
    }


    function _updatePathLineOnTheGraph(cr, ps) {
        clearPathLineOnTheGraph();
        drawPathLineOnTheGraph(cr, ps);
    }

    function drawPointsOnTheGraph(container, points) {
        let group,
            min = canvas.bounds.min.y + MARGIN;;

        _.each(points, (point, i) => {
            _drawPointWithAttributes(point, i);
        });

        function _drawPointWithAttributes(point, i) {
            let threshold = _getCurrentThresholds(point),
                pointCenter = _getPointCenter(point);

            group = container.append('g');

            _drawCircle(point, i, threshold);
            _drawThresholdText(pointCenter, i, threshold);
            _drawTriangles(pointCenter, i);
            _drawEditField(point, i, threshold);
        }

        function _drawTriangles(pointCenter, index) {
            let pointX = pointCenter[0],
                pointY = pointCenter[1],
                triangle = _getTriangleConfiguration(pointX, pointY, index);

            _appendTriangle('up');
            _appendTriangle('down');

            function _appendTriangle(type) {
                return group.append('path').attr('d', () => triangle[type].path)
                                           .attr('id', triangle[type].id)
                                           .attr('transform', triangle[type].rotate)
                                           .style('fill', triangle.style.color)
                                           .style('visibility', triangle.style.visibility);
            }
        }

        function _updateTrianglesPosition(x, y, i) {
            let triangle = _getTriangleConfiguration(x, y, i);

            _updatePosition('up');
            _updatePosition('down');

            function _updatePosition(type) {
                d3.select('#' + triangle[type].id).attr('d', d => triangle[type].path)
                                               .attr('transform', triangle[type].rotate)
                                               .style('visibility', triangle.style.visibility);
            }
        }

        function _getTriangleID(i) {
            return {
                up: 'triangle-up-' + i,
                down: 'triangle-down-' + i
            };
        }

        function _getTriangleConfiguration(x, y, i) {
            y = _getMinValueIfNull(y, min);

            return {
                up: {
                    id: _getTriangleID(i).up,
                    path: 'M ' + x + ' ' + (y - TRIANGLE_MARGIN) + ' l 6 6 l -12 0 z'
                },
                down: {
                    id: _getTriangleID(i).down,
                    path: 'M ' + x + ' ' + (y + TRIANGLE_MARGIN) + ' l 6 6 l -12 0 z',
                    rotate: 'rotate(180, ' + x + ', ' + (y + TRIANGLE_MARGIN) + ')'
                },
                style: {
                    color: canvas.triangle.fill,
                    visibility: 'hidden'
                }
            };
        }

        function _getThresholdTextID(index) {
            return {
                title: 'threshold-title-' + index,
                value: 'threshold-value-' + index
            }
        }

        function _drawThresholdText(pointCenter, index, threshold) {
            let pointX = pointCenter[0],
                pointY = _getMinValueIfNull(pointCenter[1], min);

            pointY -= TEXT_MARGIN;

            let text = {
                    title: {
                        id: _getThresholdTextID(index).title,
                        fontSize: '16px',
                        transform: 'translate(0, -' + TITLE_MARGIN + ')'
                    },
                    value: {
                        id: _getThresholdTextID(index).value,
                        fontSize: '12px',
                        cursor: 'pointer'
                    },
                    style: {
                        color: canvas.circle.fill,
                        anchor: 'middle'
                    }
                };

            _setTextAttributes('title');
            _setTextAttributes('value');
 
            _insertTextInsideTag();
            _handleEventOnTextTag(index);

            function _setTextAttributes(type) {
                let transform = text[type].transform;

                return group.append('text').attr('id', text[type].id)
                                           .attr('x', pointX)
                                           .attr('y', pointY)
                                           .attr('transform', transform)
                                           .style('fill', text.style.color)
                                           .style('font-size', text[type].fontSize)
                                           .style('text-anchor', text.style.anchor)
                                           .style('cursor', text[type].cursor);
            }

            function _insertTextInsideTag() {
                let titleID = '#' + text.title.id,
                    valueID = '#' + text.value.id;

                let value = _isThresholdValueNull(threshold.value) ? _setThresholdEmptyValue(): threshold.value,
                    cropStage = threshold.cropStageName;

                d3.select(titleID).data([cropStage]).text(d => d);
                d3.select(valueID).data([value]).text(d => d);
            }

            function _handleEventOnTextTag(i) {
                let valueID = '#' + text.value.id,
                    editField = _getEditBlockConfiguration(i, threshold),
                    editFieldID = '#' + editField.id;

                d3.select(valueID).on('mouseup', function() {
                    let group = d3.select(this.parentNode),
                        foreignObject = group.select(editFieldID),
                        editInputField = foreignObject.select('input'),
                        inputNode = editInputField.node(),
                        value = _logicService.round(threshold.value, 2);

                    foreignObject.style('display', 'block');
                    inputNode.focus();
                    inputNode.select();
                    inputNode.value = isNaN(value) ? EMPTY: value;

                    _setPointEdit(true);

                    container.on('mousedown', function() {
                        let selectedNode = d3.select(d3.event.target),
                            isSameNode = (selectedNode.attr('class') === editInputField.attr('class'));

                        if (_getPointEdit() && !isSameNode) {
                            _hideEditingBlock(foreignObject);
                        }
                    })
                });
            }
        }

        function _drawCircle(point, index, threshold) {
            let circle = _setCircleAttributes(point, index),
                savedThresholdValue = threshold.value;

            _setUpEventHandlers(circle);

            function _setCircleAttributes(p, i) {
                let pointX = p.center.x,
                    pointY = p.center.y,
                    circle = {
                        radius: canvas.circle.radius,
                        border: canvas.circle.stroke.active,
                        width: canvas.circle.stroke.width,
                        color: canvas.circle.fill,
                        index: i
                    };

                let min = canvas.bounds.min.y + MARGIN,
                    inactiveBorder = canvas.circle.stroke.inactive;

                // If y point coordinate is null, then apply inactive styles of point.
                _isPointNull(p.center.y) ? (pointY = min) && (circle.border = inactiveBorder): pointY;

                return group.append('circle').attr('cx', pointX)
                                      .attr('cy', pointY)
                                      .attr('class', 'point-' + index)
                                      .attr('r', circle.radius)
                                      .attr('stroke', circle.border)
                                      .attr('stroke-width', circle.width)
                                      .style('fill', circle.color)
                                      .style('cursor', 'pointer');
            }

            function _setUpEventHandlers(c) {
                c.on('mousedown', _clickOnCircleAndMove)
                 .on('mouseover', _onCircleOver)
                 .on('mouseout', _onCircleOut);
            }

            function _onCircleOver() {
                let self = d3.select(this);
                _broughtUponCircle(index, self);
            }

            function _onCircleOut() {
                let self = d3.select(this);
                _moveFromCircle(index, self);
            }

            function _clickOnCircleAndMove() {
                let self = d3.select(this);

                _disableMouseOut(self);
                _enableContainerMouseMove(_mouseMove);

                _pointReleased(self);
            }

            function _disableMouseOut(c) {
                c.on('mouseout', null);
            }

            function _enableContainerMouseMove(handleMouseMove) {
                container.on('mousemove', handleMouseMove);
            }

            function _pointReleased(c) {
                c.on('mouseup', _mouseIsOutOfTheCircle);

                container.on('mouseup', _mouseIsOutOfTheCircle);
                container.on('mouseleave', _mouseIsOutOfTheCircle);

                function _mouseIsOutOfTheCircle() {
                    _moveFromCircle(index, c);

                    c.on('mouseout', function() { 
                        let self = d3.select(this);
                        _moveFromCircle(index, self);
                    });
                    container.on('mousemove', null);
                }
            }

            function _mouseMove() {
                let yPos = d3.mouse(this)[1];
                _updatePoint(point, circle, threshold, savedThresholdValue, yPos, index);
            }
        }

        function _drawEditField(point, index, threshold) {
            let x = point.center.x - TEXT_MARGIN,
                y = _getMinValueIfNull(point.center.y, min) - EDIT_FIELD_MARGIN,
                circle = d3.select('.point-' + index),
                savedThresholdValue = threshold.value;

            let editBlock = _getEditBlockConfiguration(index, threshold);

            group.append('foreignObject').attr('x', x)
                                         .attr('y', y)
                                         .attr('width', EDIT_FIELD_WIDTH)
                                         .attr('height', '24px')
                                         .attr('id', editBlock.id)
                                         .style('display', editBlock.style.display)
                                         .append('xhtml:input')
                                         .attr('type', editBlock.type)
                                         .attr('value', editBlock.value)
                                         .attr('class', 'f-aside-thresholds-point-edit-field')
                                         .on('keyup', _handleValueOnInput);

            function _handleValueOnInput() {
                let self = this,
                    fieldValue = self.value,
                    key = d3.event.keyCode;

                if ((key !== KEY_CODES.BACKSPACE) &&
                    (key !== KEY_CODES.CMD.LEFT)  &&
                    (key !== KEY_CODES.CMD.RIGHT) &&
                    (key !== KEY_CODES.CTRL) &&
                    (key !== KEY_CODES.ALT) &&
                    (key !== KEY_CODES.ARROW.LEFT) &&
                    (key !== KEY_CODES.ARROW.RIGHT)) {
                    _setInputFieldValue(self, fieldValue);
                }

                if (key === KEY_CODES.ESCAPE) {
                    let foreignObject = d3.select(self.parentNode);
                    _hideEditingBlock(foreignObject);
                }

                if (key === KEY_CODES.ENTER) {
                    let value = _logicService.round(parseFloat(fieldValue), 2),

                        y = _toPixels({
                            x: 0,
                            y: value
                        }).y,
                        foreignObject = d3.select(self.parentNode);;

                    isNaN(y) ? y = canvas.bounds.min.y + BORDER / 2: y;

                    _hideEditingBlock(foreignObject);
                    _updatePoint(point, circle, threshold, savedThresholdValue, y, index);
                    _moveFromCircle(index, circle);

                    self.value = isNaN(value) ? EMPTY: value;
                }
            }

            function _setInputFieldValue(s, fv) {
                let valueInNumber = parseFloat(fv);

                if (isNaN(valueInNumber) || fv < 0) {
                    s.value = EMPTY;
                } else if (valueInNumber > settings.maxThreshold) {
                    s.value = settings.maxThreshold;
                } else {
                    s.value = fv;
                }
            }
        }

        function _getEditBlockConfiguration(i, t) {
            let value = _isThresholdValueNull(t.value) ? EMPTY: t.value;

            return {
                id: 'point-edit-control-' + i,
                type: 'text',
                value: value,
                style: {
                    display: 'none'
                }
            };
        }

        function _getPointCenter(p) {
            return [p.center.x, p.center.y];
        }

        function _isPointNull(y) {
            return y === null;
        }

        function _getMinValueIfNull(v, m) {
            return _isPointNull(v) ? m: v;
        }

        function _getCurrentThresholds(p) {
            let dangerThresholds = getDangerThresholds();

            return _.find(dangerThresholds, threshold => threshold.cropStageId === p.threshold.cropStageId);
        }

        function _isThresholdValueNull(value) {
            return value === null;
        }

        function _setThresholdEmptyValue() {
            return EMPTY;
        }

        function _hideEditingBlock(el) {
            el.style('display', 'none');
            _setPointEdit(false);

            container.on('mousedown', null);
        }

        function _showEditingBlock(el) {
            el.style('display', 'block');
            _setPointEdit(true);
        }

        function _transformPixelsIntoValues(y) {
            let max = canvas.bounds.max.y,
                min = canvas.bounds.min.y;

            return settings.maxThreshold - (y - BORDER) / (Math.abs(max - min) / settings.maxThreshold)
        }

        function _broughtUponCircle(i) {
            let self = this ? this: ('.' + arguments[1].attr('class')),
                circleState = _circleInActiveState();

            _setPointState(circleState, self, i);
        }

        function _moveFromCircle(i) {
            let self = this ? this: ('.' + arguments[1].attr('class')),
                circleState = _circleInNormalState();

            _setPointState(circleState, self, i);
        }

        function _circleInActiveState() {
            return {
                    radius: canvas.circle.radius * 1.25,
                    visibility: 'visible'
            };
        }

        function _circleInNormalState() {
            return {
                    radius: canvas.circle.radius,
                    visibility: 'hidden'
            };
        }

        function _setPointState(circle, self, i) {
            d3.select(self).transition().attr('r', circle.radius);

            let triangleUp = d3.select('#' + _getTriangleID(i).up),
                triangleDown = d3.select('#' + _getTriangleID(i).down);

            triangleUp.style('visibility', circle.visibility);
            triangleDown.style('visibility', circle.visibility);
        }

        function _updatePointInforAttributes(point, i) {
            let x = point.center.x,
                y = point.center.y,
                threshold = _getCurrentThresholds(point);

            _updateTrianglesPosition(x, y, i);
            _updateEditFieldPosition(y, i, threshold);
            _updateThresholdTitle(y, i);
            _updateThresholdValue(y, i, threshold);
        }

        function _updateEditFieldPosition(y, i, t) {
            let editBlock = _getEditBlockConfiguration(i, t),
                editBlockID = '#' + editBlock.id,
                value = _isThresholdValueNull(t.value) ? _setThresholdEmptyValue(): t.value,
                pos = y - EDIT_FIELD_MARGIN;

            let foreignObject = d3.select(editBlockID),
                inputField = foreignObject.selectAll('input');

            foreignObject.attr('y', pos);
            inputField.attr('value', value);
        }

        function _updateThresholdTitle(y, i) {
            let titleID = '#' + _getThresholdTextID(i).title,
                pos = y - TEXT_MARGIN;

            d3.select(titleID).attr('y', pos);
        }

        function _updateThresholdValue(y, i, t) {
            let valueID = '#' +_getThresholdTextID(i).value,
                pos = y - TEXT_MARGIN,
                value = _isThresholdValueNull(t.value) ? _setThresholdEmptyValue(): t.value;

            d3.select(valueID).attr('y', pos)
                              .data([value])
                              .text(d => d)
                              .style('text-anchor', 'middle');
        }

        function _updatePoint(p, c, t, s, yPos, i) {
            if (_isPointInsideBounds(yPos)) {
                if (_isPointBiggerThanMax(yPos)) {
                    let max = canvas.bounds.max.y;

                    yPos = max;

                    _setCircleAttributesToActiveState(c, yPos);
                    _setThresholdLevel(t, settings.maxThreshold);

                } else if (_isPointEqualZero(yPos)) {
                    let lowerLimit = canvas.bounds.min.y;

                    yPos = lowerLimit;

                    _setCircleAttributesToActiveState(c, yPos);
                    _setThresholdLevel(t, 0);

                } else if (_isPointLowerThanZero(yPos)) {
                    let min = canvas.bounds.min.y + MARGIN;

                    yPos = min;

                    _setCircleAttributesToInactiveState(c, yPos);
                    _setThresholdLevel(t, null);
                } else {
                    let posValue = _transformPixelsIntoValues(yPos);
                    posValue = _logicService.round(posValue, 2);

                    _setCircleAttributesToActiveState(c, yPos);
                    _setThresholdLevel(t, posValue);
                }

                p.center.y = yPos;

                _updatePathLineOnTheGraph(container, points);
                _updatePointInforAttributes(p, i);

                _broughtUponCircle(i, c);

                if (_wereThresholdsChanged(s, t)) {
                    settings.thresholds[settings.thresholdKey].wasChanged = true;
                }
            }
        }

        function _isPointInsideBounds(y) {
            let min = canvas.bounds.max.y - BORDER,
                max = canvas.bounds.min.y + BORDER;

            return (y >= min) && (y <= max);
        }

        function _isPointBiggerThanMax(y) {
            let max = canvas.bounds.max.y;

            return y <= max;
        }

        function _isPointEqualZero(y) {
            let zero = canvas.bounds.min.y,
                limit = zero + BORDER / 3;

            return y >= zero && y <= limit;
        }

        function _isPointLowerThanZero(y) {
            let min = canvas.bounds.min.y;

            return y >= min;
        }

        function _wereThresholdsChanged(s, t) {
            return s !== t.value;
        }


        function _setThresholdLevel(threshold, value) {
            threshold.value = value;
        }

        function _setCircleAttributesToActiveState(c, y) {
            _getCircleState(c, y).attr('stroke', canvas.circle.stroke.active);
        }

        function _setCircleAttributesToInactiveState(c, y) {
            _getCircleState(c, y).attr('stroke', canvas.circle.stroke.inactive);
        }

        function _getCircleState(c, y) {
            return c.attr('cy', y)
                         .style('fill', canvas.circle.fill);
        }
    }

    function drawGraph(container, points, lines) {
        drawLegendOnTheGraph(container, lines);
        drawPathLineOnTheGraph(container, points);
        drawPointsOnTheGraph(container, points);
    }


    function init(options) {
        let aside = elements.aside = $('.f-aside-thresholds');
        _saveOptions(options);

        updateCanvasAndStages();

        let container = getCanvasContainer(),
            points = getGraphPoints(),
            lines = getGraphLegend();

        drawGraph(container, points, lines);
    }

    /**
     *
     * @param options - Includes graph settings: graph name, insect threshold and sowing data
     */
    this.init = init;
    this.clearCanvas = clearCanvas;

});